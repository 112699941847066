<template>
  <div class="HealthyLife">
    <HeaderHot :title="'健康生活'" :currentIndex="6"></HeaderHot>
    <Breadcrumb :navTitle="'健康生活'"></Breadcrumb>
    <div class="healthy-content">
      <div class="healthy-left">
        <div class="healthy-top">
          <img :src="BaseUrl + itemData.topList[0].image" alt="" />
          <span>{{ itemData.topList[0].title }}</span>
        </div>
        <div class="healthy-item">
          <div
            class="item-list"
            v-for="item in itemList"
            :key="item.id"
            @click="goHealthyDetails(item.id)"
          >
            <img :src="BaseUrl + item.image" alt="" />
            <div>
              <span>{{ item.title }}</span>
              <span>{{ item.createtime }}</span>
            </div>
          </div>
        </div>
        <div class="pageDown">
          <el-pagination
            background
            prev-text="上一页"
            next-text="下一页"
            @prev-click="pagePrev"
            @next-click="pageNext"
            @current-change="pageChange"
            layout=" prev, pager, next"
            page-size="8"
            :total="totalPage"
          >
          </el-pagination>
        </div>
      </div>
      <div class="healthy-right">
        <div class="healthy-title">
          <div>
            <span>权威发布</span>
          </div>
          <div class="title-list">
            <div
              class="item-list"
              v-for="item in itemData.topList.slice(1, 4)"
              :key="item.id"
              @click="goHealthyDetails(item.id)"
            >
              <img :src="BaseUrl + item.image" alt="" />
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
        <div class="healthy-item">
          <div
            class="list-item"
            v-for="item in itemData.tuiList"
            :key="item.id"
            @click="goHealthyDetails(item.id)"
          >
            <div>
              <img :src="BaseUrl + item.image" alt="" />
              <span>{{ item.title }}</span>
            </div>
            <div>
              <span>{{ item.brief }}</span>
              <span>[详细]</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderHot from "../../../components/HeaderHot.vue";
import Breadcrumb from "../../../components/Breadcrumb.vue";
import Footer from "../../../components/Footer.vue";
export default {
  name: "HealthyLife",
  data() {
    return {
      page: 1,
      pageSize: 8,
      totalPage: "",
      itemData: [],
      itemList: [],
    };
  },
  created() {
    this.getData();
    this.getList();
  },
  methods: {
    // 获取健康生活页面数据
    getData() {
      this.$http({
        url: "/live/index",
      }).then((res) => {
        console.log(res, "res");
        this.itemData = res.data;
      });
    },
    created() {
      this.getData();
      this.getList();
    },
    // 获取健康生活列表
    getList(page) {
      this.$http({
        url: "/live/list",
        params: {
          page: page ? page : this.page,
          pagesize: this.pageSize,
        },
      }).then((res) => {
        console.log(res, "res");
        this.itemList = res.data.list;
        this.totalPage = res.data.total;
      });
    },
    // 上一页
    pagePrev() {
      this.page--;
      this.getList(this.page);
    },
    // 下一页
    pageNext() {
      console.log("下一页");
      this.page++;
      this.getList(this.page);
    },
    // 页面改变
    pageChange(e) {
      console.log(e);
      this.page = e;
      this.getList(this.page);
    },
    //跳转健康生活详情
    goHealthyDetails(id) {
      this.$router.push(`/healthylifedetails/${id}`);
    },
  },
  components: {
    HeaderHot,
    Breadcrumb,
    Footer,
  },
};
</script>

<style lang="less" scoped>
.HealthyLife {
  .healthy-content {
    display: flex;
    width: 1200px;
    margin: 0 auto -27px;
    .healthy-left {
      width: 664px;
      margin-right: 36px;
      .healthy-top {
        position: relative;
        width: 664px;
        height: 400px;
        margin-bottom: 38px;
        > img:nth-child(1) {
          position: absolute;
          width: 664px;
          height: 400px;
        }
        > span:nth-child(2) {
          position: absolute;
          bottom: 0;
          display: block;
          width: 664px;
          height: 40px;
          line-height: 40px;
          text-indent: 1.5em;
          background-color: rgba(0, 0, 0, 0.32);
          color: #fff;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .healthy-item {
        .item-list {
          display: flex;
          align-items: center;
          width: 664px;
          height: 125px;
          padding-bottom: 23px;
          margin-bottom: 35px;
          border-bottom: 2px dashed #b5b5b5;
          box-sizing: border-box;
          cursor: pointer;
          > img:nth-child(1) {
            width: 180px;
            height: 100px;
            margin-right: 16px;
          }
          > div:nth-child(2) {
            width: 470px;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > span:nth-child(1) {
              font-size: 17px;
              color: #333333;
            }
            > span:nth-child(1):hover {
              text-decoration: underline;
            }
            > span:nth-child(2) {
              font-size: 12px;
              color: #999999;
            }
          }
        }
      }
      .pageDown {
        width: 664px;
        margin: 60px auto 0;
        text-align: center;
      }
    }
    .healthy-right {
      width: 500px;
      .healthy-title {
        margin-top: -10px;
        margin-bottom: 79px;
        > div:nth-child(1) {
          width: 500px;
          height: 53px;
          margin-bottom: 30px;
          border-bottom: 3px solid #12407d;
          > span:nth-child(1) {
            font-size: 26px;
            color: #333333;
          }
        }

        .title-list {
          width: 500px;
          .item-list {
            display: flex;
            margin-bottom: 22px;
            cursor: pointer;
            > img:nth-child(1) {
              width: 130px;
              height: 85px;
              margin-right: 28px;
            }
            > div:nth-child(2) {
              font-size: 24px;
              margin-top: 10px;
              color: #333333;
            }
            > span:nth-child(2):hover {
              text-decoration: underline;
            }
          }
        }
      }
      .healthy-item {
        width: 500px;
        .list-item {
          margin-bottom: 45px;
          > div:nth-child(1) {
            position: relative;
            width: 500px;
            height: 300px;
            > img:nth-child(1) {
              position: absolute;
              width: 500px;
              height: 300px;
            }
            > span:nth-child(2) {
              width: 500px;
              height: 40px;
              position: absolute;
              bottom: 0;
              text-indent: 1.5em;
              line-height: 40px;
              background: rgba(100, 100, 100, 0.33);
              color: #ffffff;
            }
          }
          > div:nth-child(2) {
            margin-top: 32px;
            font-size: 14px;
            color: #666666;
            > span:nth-child(2) {
              font-size: 16px;
              color: #2050ac;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
